/**
 * Make urls relative and lower environmentally friendly.  Nullish values fall back to 404
 * @param {string} url 
 * @returns string url
 */

export default function envUrl(url = ""){
    return (url?.includes("http") && window.location.host !== "authenteak.com") ?  url?.split("com")[1] : (url ? url : "/404");
}

