import clsx from "clsx";
import Style from "../DesktopFlyout.module.scss"
import Link from 'next/link';


function Item({ title, href = "#", className }){
    return(
        <li className={clsx(Style.item, className)}>
            <Link
                href={href}
                title={`visit ${title} section`}
                className={Style.itemLink}
            >
                {title}
            </Link>
        </li>
    )
}

export default Item;