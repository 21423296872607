import React, { useEffect, useState } from "react";
import clsx from 'clsx';
import Style from './MobileFlyout.module.scss';
import { useRouter } from "next/navigation";
import { GetHeaderNav } from "services/header/HeaderContext.service";
import FlyoutItem from "../components/FlyoutItem";



function MobileView({ id }){
    const [ headerFlyoutData, setHeaderFlyoutData ] = useState([]) 

    useEffect(() => {
        GetHeaderNav(id).then(response => {
            setHeaderFlyoutData(response)
        })

    }, [ id ])


    const router = useRouter()

    const [ toggle, setToggle ] = useState(false)
    const [ toggleId, setToggleId ] = useState(null)

    function toggleChildren(id){
        setToggle(!toggle)
        setToggleId(id)
    }  


    return(
        <ul className={Style.listFlyout}>
            {Array.isArray(headerFlyoutData) && headerFlyoutData.map(item => {
                if(item.title !== "Featured" && !item.title.includes("Quick Ship") && item.children.length) {
                    return (
                        <React.Fragment key={item.id}>
                            <li className={Style.item}>
                                <button 
                                    className={Style.listLink} 
                                    type="button" 
                                    onClick={() => toggleChildren(item.id)}
                                >
                                    <h3 className={clsx((toggleId === item.id && toggle) && Style.active, Style.listHeadingFlyout)}>
                                        {item.title}
                                    </h3>
                                </button> 

                                {item.children.length ? 
                                    <div 
                                        className={clsx(
                                            Style.itemCntr, 
                                            (toggle && toggleId === item.id.toString()) && Style.itemCntrShow 
                                        )} 
                                    >
                                        {item.children.map(kid => (
                                            <FlyoutItem {...kid} key={kid.id} />
                                        ))}
                                    </div>
                                : null}                        
                            </li>
                        </React.Fragment>
                    )


                }else if( item.title.includes("Quick Ship") ){
                    return (
                        <React.Fragment key={item.id}>
                            <li className={Style.item}>
                                <button 
                                    className={Style.listLink} 
                                    type="button" 
                                    onClick={() => router.push(item.url)}
                                >
                                    <h3 className={clsx( Style.listHeadingFlyout, Style.goToHeading)} data-menu-url={item.url}>
                                        {item.title}
                                    </h3>
                                </button>                        
                            </li>
                        </React.Fragment>
                    )


                }else if(item.title !== "Featured"){
                    return <FlyoutItem {...item} key={item.id}/>
                }                   
            })}


            <li className={clsx(Style.itemCntr, Style.itemCntrShow)}>
                {Array.isArray(headerFlyoutData) && headerFlyoutData.map(item => {
                    if(item.title === "Featured"){
                        return(
                            <React.Fragment key={item.id}>
                                {item.children.map(kid => {                                    
                                    return <FlyoutItem {...kid} key={kid.id} />
                                })}
                            </React.Fragment>
                        )
                    }
                })}
            </li>
        </ul>
    )
}


export default MobileView;