"use client"

import Blogs from "./sections/Blogs";
import Columns from "./sections/Columns";
import Style from "./DesktopFlyout.module.scss"
import Featured from "./sections/Featured";
import { GetFlyoutNav } from "services/header/HeaderContext.service";
import { Suspense, useEffect, useState } from "react";
import PreLoader from "./components/PreLoader";
import clsx from "clsx";
import { LocalStorage } from "services/LocalStorage.service";


function DesktopView({ type, height }){
    const [ featured, setFeatured ] = useState(null)
    const [ columns, setColumns ] = useState(null)
    const [ blogs, setBlogs ] = useState(null)
    const [ megaNav, setMegaNav ] = useState({})

    
    useEffect(() => {
        const cachedNav = LocalStorage.getStorage(type)

        if( cachedNav ){
            setMegaNav(cachedNav)
            return;

        }else{
            GetFlyoutNav(type).then((response) => {
                LocalStorage.setStorage(type, response)
                setMegaNav(response)
            })
        }

    }, [ type ])



    useEffect(() => {
        if( Array.isArray(megaNav) ){
            const cols = megaNav?.filter((item) => item.typeHandle === "column")
            setColumns(cols)

            const feat = megaNav?.filter((item) => item.typeHandle === "featured")
            setFeatured(feat[0])

            const blog = megaNav?.filter((item) => item.typeHandle === "blogs") 
            setBlogs(blog[0])
        }

    },[ megaNav ])


    return(
        <Suspense fallback={<PreLoader />}>
            <ul className={Style.block} style={{ listStyle: "none" }}>
                {featured && (
                    <li className={clsx(Style.featured, Style[height])}>
                        <Featured {...featured} />
                    </li>
                )}
                
                <li className={clsx(Style.main, Style[height])}>
                    {columns && (
                        <ul className={Style.columns}>
                            <Columns {...{ columns }} />
                        </ul>
                    )}

                    {/* <ul className={Style.blogs}>
                        <Blogs {...blogs} />
                    </ul> */}
                </li>
            </ul>
        </Suspense>
    )
}


export default DesktopView;