import clsx from "clsx";
import Style from "../DesktopFlyout.module.scss"
import Figure from "../components/Figure";
import Heading from "../components/Heading";
import Item from "../components/Item";



function Blogs({ blogImage = [], blogPosts = [] }){

    return(
        <ul className={clsx(Style.section, Style.sectionRow)}>
            <Figure 
                className={Style.blogFigure}
                alt="Blogs from the Pros"
                href="/blog" 
                {...blogImage[0]}
            />

            <li className={Style.blogRow}>
                <ul className={clsx(Style.blogTitleColumn)}>
                    <Heading title="From the pros" />
                </ul>

                <ul className={Style.blogItemRow}>
                    {Array.isArray(blogPosts) && blogPosts.map((post) => {
                        return(
                            <Item 
                                key={post?.id}
                                href={post?.blogHref}
                                title={post?.blogTitle}
                                className={Style.blogItem}
                            />
                        )
                    })}
                </ul>
            </li>
            
        </ul>
    )
}

export default Blogs;