import Skeleton from "components/feedback/Skeleton";
import Style from "../DesktopFlyout.module.scss"
import utils from 'styles/globals/utils.module.scss';
import clsx from "clsx";


function PreLoader({ height }){
    return(
        <div className={clsx(Style.block, Style[height])}>
            <div className={clsx(Style.featured, utils.p_2, Style[height])}>
                <Skeleton width="100%" height={100} className={utils.mb_1} />
                <Skeleton width="40%" height={10} />

                <Skeleton width="100%" height={100} className={clsx(utils.mb_1, utils.mt_3)} />
                <Skeleton width="40%" height={10} />

                <Skeleton width="70%" height={20} className={utils.mt_4}/>
                <Skeleton width="50%" height={20} className={utils.mt_3}/>
                <Skeleton width="60%" height={20} className={utils.mt_3}/>
            </div>


            <div className={clsx(Style.main, Style[height])}>
                <div className={Style.columns}>
                    <div className={clsx(Style.columnList, utils.w_25, utils.mb_2)}>
                        <Skeleton width="70%" height={20}/>
                        <Skeleton width="40%" height={10} className={utils.mt_1}/>
                        <Skeleton width="45%" height={10} className={utils.mt_1}/>
                        <Skeleton width="35%" height={10} className={utils.mt_1}/>
                        <Skeleton width="50%" height={10} className={utils.mt_1}/>
                        <Skeleton width="40%" height={10} className={utils.mt_1}/>
                    </div>


                    <div className={clsx(Style.columnList, utils.w_25, utils.mb_2)}>
                        <Skeleton width="70%" height={20}/>
                        <Skeleton width="40%" height={10} className={utils.mt_1}/>
                        <Skeleton width="45%" height={10} className={utils.mt_1}/>
                        <Skeleton width="35%" height={10} className={utils.mt_1}/>
                        <Skeleton width="50%" height={10} className={utils.mt_1}/>
                    </div>


                    <div className={clsx(Style.columnList, utils.w_25)}>
                        <Skeleton width="70%" height={20}/>
                        <Skeleton width="40%" height={10} className={utils.mt_1}/>
                        <Skeleton width="45%" height={10} className={utils.mt_1}/>
                        <Skeleton width="35%" height={10} className={utils.mt_1}/>
                        <Skeleton width="35%" height={10} className={utils.mt_1}/>
                    </div>


                    <div className={clsx(Style.columnList, utils.w_25, utils.mt_2)}>
                        <Skeleton width="70%" height={20}/>
                        <Skeleton width="40%" height={10} className={utils.mt_1}/>
                        <Skeleton width="45%" height={10} className={utils.mt_1}/>
                        <Skeleton width="35%" height={10} className={utils.mt_1}/>
                        <Skeleton width="35%" height={10} className={utils.mt_1}/>
                    </div>

        

                    <div className={clsx(Style.columnList, utils.w_25)}>
                        <Skeleton width="70%" height={20} className={utils.mb_2}/>

                        <span className={clsx(utils.d_flex, utils.w_100, utils.align_items_center)}>
                            <Skeleton variant="circle" width={40} height={40} className={utils.me_1} />
                            <Skeleton variant="text" width="50%" />
                        </span>

                        <span className={clsx(utils.d_flex, utils.w_100, utils.align_items_center, utils.mt_2)}>
                            <Skeleton variant="circle" width={40} height={40} className={utils.me_1} />
                            <Skeleton variant="text" width="50%" />
                        </span>

                        <span className={clsx(utils.d_flex, utils.w_100, utils.align_items_center, utils.mt_2)}>
                            <Skeleton variant="circle" width={40} height={40} className={utils.me_1} />
                            <Skeleton variant="text" width="50%" />
                        </span>

                        <span className={clsx(utils.d_flex, utils.w_100, utils.align_items_center, utils.mt_2)}>
                            <Skeleton variant="circle" width={40} height={40} className={utils.me_1} />
                            <Skeleton variant="text" width="50%" />
                        </span>

                        <span className={clsx(utils.d_flex, utils.w_100, utils.align_items_center, utils.mt_2)}>
                            <Skeleton variant="circle" width={40} height={40} className={utils.me_1} />
                            <Skeleton variant="text" width="50%" />
                        </span>
                    </div>
                   
                </div>

                <div className={Style.blogs}>
                    <div className={clsx(Style.section, Style.sectionRow)}>
                        <Skeleton width="100%" height={100} className={clsx(utils.mb_1, utils.me_2, utils.w_25)} />

                        <span className={Style.blogItem}>
                            <Skeleton variant="text" width="50%" />
                            <Skeleton variant="text" width="50%" />
                            <Skeleton variant="text" width="20%" />
                        </span>

                        <span className={Style.blogItem}>
                            <Skeleton variant="text" width="50%" />
                            <Skeleton variant="text" width="50%" />
                            <Skeleton variant="text" width="20%" />
                        </span>

                        <span className={Style.blogItem}>
                            <Skeleton variant="text" width="50%" />
                            <Skeleton variant="text" width="50%" />
                            <Skeleton variant="text" width="20%" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreLoader;