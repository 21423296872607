import React, { Suspense, useContext } from "react";
import Style from "./MegaFlyout.module.scss"
import { HeaderContext } from 'components/applications/header_footer/Header/contexts/HeaderContext';
import MobileView from "./MobileView";
import DesktopView from "./DesktopView";
import clsx from "clsx";
import PreLoader from "./DesktopView/components/PreLoader";


function MegaFlyout({ inMobileFlyout, type, id, height = "default" }){
    const headerCtx = useContext(HeaderContext)

    return(
        <ul className={clsx(Style?.block, Style?.[height], !!headerCtx.flyoutShown && Style?.visible)} data-main-menu={type}>
            <li className={Style.viewCntr}>
                {inMobileFlyout ? 
                    <MobileView {...{ id }} />
                :
                    <Suspense fallback={<PreLoader />}>
                        <DesktopView {...{ type, height }} />
                    </Suspense>
                }
            </li>
        </ul>
    )
}


export default React.memo(MegaFlyout);