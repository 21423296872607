import clsx from "clsx";
import Style from "../DesktopFlyout.module.scss"
import Figure from "../components/Figure";
import Heading from "../components/Heading";
import Item from "../components/Item";


function Columns({ columns = [] }){
    return(
        <> 
            {Array.isArray(columns) && columns.map((column) => {
                const { headingImage, headingHref = "#", id, headingTitle = "", kids = [] } = column ?? {};

                return(
                    <li className={Style.column} key={id}>
                        <ul className={clsx(Style.columnList, kids.length <= 5 && Style.columnsListCollapse)}>
                            <Heading 
                                title={headingTitle}
                                {...headingImage?.[0]}
                                href={headingHref}
                            />

                            {kids.map((item) => {
                                if( item.kidImg.length ){
                                    return(
                                        <Figure 
                                            key={item?.id}
                                            href={item?.kidHref}
                                            title={item?.kidTitle}
                                            {...item.kidImg[0]}
                                            width={item?.kidDisplay === "imageOnly" ? 300 : 40}
                                            height={item?.kidDisplay === "imageOnly" ? 75 : 40}
                                            isRow={item?.kidDisplay === "imageText"}
                                            displayType={item?.kidDisplay}
                                        />
                                    )

                                }else if( item.kidDisplay === "textOnly" ){
                                    return(
                                        <Item 
                                            key={item?.id}
                                            href={item?.kidHref}
                                            title={item?.kidTitle}
                                        />
                                    )
                                }
                            })}
                        </ul>
                    </li>
                )
            })}
        </>
    )
}

export default Columns;