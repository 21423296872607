import Image from "next/image";
import Style from "../DesktopFlyout.module.scss"
import clsx from "clsx";
import Link from "next/link";
import { blurImage } from "lib/Constants";
import { cloudinaryCmsImageLoader } from "thirdparty/Cloudinary/cloudinaryLoader";


function Heading({ 
    title, 
    width = 50, 
    height = 30, 
    alt = "", 
    src = "",
    href = null,
    filename
}){
    return(
        <li className={clsx(Style.heading, src && Style.headingWithImage)}>
            {href ? (
                <Link 
                    href={href ?? "#"} 
                    alt={`Go to ` + title}
                    className={Style.headingLink}
                >
                    <Content 
                        {...{ title, width, height, alt, src, filename }} 
                    />
                </Link>

            ):(

                <Content 
                    {...{ title, width, height, alt, src, filename }} 
                />
            )}
        </li>
    )
}


function Content({
    title, 
    width = 50, 
    height = 30, 
    alt = "", 
    src = null,
    filename
}){
    return(
        <>
            {title}

            {filename && (
                <Image 
                    width={width}
                    height={height}
                    alt={title ?? "image"}
                    src={filename ?? blurImage}
                    placeholder="blur"
                    blurDataURL={blurImage}
                    className={Style.headingImage}
                    loader={cloudinaryCmsImageLoader}
                    onError={(e) => {
                        e.target.src = blurImage;
                    }}
                />
            )}
        </>
    )
}


export default Heading;