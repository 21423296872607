
import clsx from "clsx";
import Style from "../DesktopFlyout.module.scss"
import Image from 'next/image';
import Link from 'next/link';
import { blurImage } from "lib/Constants";
import { cloudinaryCmsImageLoader } from "thirdparty/Cloudinary/cloudinaryLoader";


function Figure({ 
    href = "#", 
    src = "", 
    alt = "", 
    filename,
    width = 300, 
    height = 100,
    title,
    className,
    isRow,
    url = "",
    displayType
}){
    return(
        <li className={clsx(Style.figure, className)}>
            <Link 
                href={href} 
                title={title} 
                className={clsx(Style.figureLink, isRow && Style.figureRow)}
            >
                <Image 
                    width={width}
                    height={height}
                    alt={title ?? "image"}
                    src={filename ?? blurImage}
                    placeholder="blur"
                    blurDataURL={blurImage}
                    className={clsx(Style.image, isRow && Style.figureRowImage, displayType === "imageOnly" && Style.imageOnly)}
                    loader={cloudinaryCmsImageLoader}
                    onError={(e) => {
                        e.target.src = blurImage;
                        e.target.style.objectFit = "cover";
                        e.target.style.width = "100%";
                        e.target.style.height = "100px";
                    }}
                />

                {displayType === "imageText" && (
                    <p className={Style.imageTitle}>
                        {title}
                    </p>
                )}
            </Link>
        </li>
    )
}


export default Figure;