import Link from "next/link";
import envUrl from 'lib/Utils/envUrl';
import clsx from 'clsx';

import Style from './FlyoutItem.module.scss';



function FlyoutItem(kid){
    return (
        <li className={Style.item}>
            <Link
                href={envUrl(kid.url)}
                className={clsx(Style.listLink, Style.subLink)}
                title={`Go to ${kid.title}`}
            >
                {kid.title}
            </Link>
        </li>
    );
}

export default FlyoutItem;