import Style from "../DesktopFlyout.module.scss"

import Heading from '../components/Heading';
import Figure from '../components/Figure';
import Divider from 'components/display/Divider';
import Item from '../components/Item';
import React from "react";



function Featured({ features = [] }){


    return(
        <ul className={Style.section}>
            <Heading title="Featured" />

            {Array.isArray(features) && features.map((item) => {
                if( item.featureImage.length ){
                    return (
                        <Figure
                            key={item?.id} 
                            title={item?.featureTitle}
                            displayType="imageText"
                            href={item?.featureHref} 
                            {...item.featureImage[0]}
                        />
                    )

                }else{

                    return(
                        <React.Fragment key={item?.id}>
                            <Divider 
                                className={Style.divider} 
                                color="ice"
                            />

                            <Item 
                                href={item?.featureHref} 
                                title={item?.featureTitle}
                            />
                        </React.Fragment>
                    )
                }
            })}
        </ul>
    )
}

export default Featured;